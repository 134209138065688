<script setup lang="ts">
// Needs to be a power of 2
const numberOfSamples = ref(1024)
// Radius of the circle
const radius = ref(20)
// How many circles to make
const segments = 12
// Distance between circles
const distanceBetweenCircles = 50
// Boost to the signal
const amplifier = 0.08
// Color
const color = '#FFFD5A'

const audioTrack = '/audio/its-pronounced-voix-heller.mp3'
const audio1 = ref<HTMLAudioElement | null>(null)
const audioContext = ref<AudioContext | null>(null)
const analyser = ref<AnalyserNode | null>(null)

const ctx = ref<CanvasRenderingContext2D | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)

const theAnimation = ref(0)
const lastTime = ref(0)
const interval = 1000 / 60
const timer = ref(0)

const bufferLength = ref(0)
const dataArray: Ref<Uint8Array | null> = ref(null)

// A lot of this code is from ForExamleTheCat:
// https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas
function drawLines(pts: number[]) {
  if (!ctx.value)
    return

  ctx.value.moveTo(pts[0], pts[1])
  for (let i = 2; i < pts.length - 1; i += 2) ctx.value.lineTo(pts[i], pts[i + 1])
}

function getCurvePoints(pts: number[], tension: number = 0.5, isClosed: boolean = false, numOfSegments: number = 16) {
  let _pts = []; const res = [] // clone array
  let x; let y // our x,y coords
  let t1x; let t2x; let t1y; let t2y // tension vectors
  let c1; let c2; let c3; let c4 // cardinal points
  let st; let t; let i // steps based on num. of segments

  // clone array so we don't change the original
  //
  _pts = pts.slice(0)

  // The algorithm require a previous and next point to the actual point array.
  // Check if we will draw closed or open curve.
  // If closed, copy end points to beginning and first points to end
  // If open, duplicate first points to befinning, end points to end
  if (isClosed) {
    _pts.unshift(pts[pts.length - 1])
    _pts.unshift(pts[pts.length - 2])
    _pts.unshift(pts[pts.length - 1])
    _pts.unshift(pts[pts.length - 2])
    _pts.push(pts[0])
    _pts.push(pts[1])
  }
  else {
    _pts.unshift(pts[1]) // copy 1. point and insert at beginning
    _pts.unshift(pts[0])
    _pts.push(pts[pts.length - 2]) // copy last point and append
    _pts.push(pts[pts.length - 1])
  }

  // ok, lets start..

  // 1. loop goes through point array
  // 2. loop goes through each segment between the 2 pts + 1e point before and after
  for (i = 2; i < (_pts.length - 4); i += 2) {
    for (t = 0; t <= numOfSegments; t++) {
      // calc tension vectors
      t1x = (_pts[i + 2] - _pts[i - 2]) * tension
      t2x = (_pts[i + 4] - _pts[i]) * tension

      t1y = (_pts[i + 3] - _pts[i - 1]) * tension
      t2y = (_pts[i + 5] - _pts[i + 1]) * tension

      // calc step
      st = t / numOfSegments

      // calc cardinals
      c1 = 2 * st ** 3 - 3 * st ** 2 + 1
      c2 = -(2 * st ** 3) + 3 * st ** 2
      c3 = st ** 3 - 2 * st ** 2 + st
      c4 = st ** 3 - st ** 2

      // calc x and y cords with common control vectors
      x = c1 * _pts[i] + c2 * _pts[i + 2] + c3 * t1x + c4 * t2x
      y = c1 * _pts[i + 1] + c2 * _pts[i + 3] + c3 * t1y + c4 * t2y

      // store points in array
      res.push(x)
      res.push(y)
    }
  }

  return res
}

function drawCurve(ptsa: number[], tension: number = 0.5, isClosed: boolean = false, numOfSegments: number = 16, showPoints: boolean = false) {
  if (!ctx.value)
    return

  ctx.value.beginPath()

  drawLines(getCurvePoints(ptsa, tension, isClosed, numOfSegments))

  if (showPoints) {
    ctx.value.beginPath()
    for (let i = 0; i < ptsa.length - 1; i += 2)
      ctx.value.rect(ptsa[i] - 2, ptsa[i + 1] - 2, 4, 4)
  }

  ctx.value.stroke()
}

onMounted(() => {
  canvas.value = document.getElementById('canvas1') as HTMLCanvasElement

  if (!canvas.value)
    return

  canvas.value.width = 1500
  canvas.value.height = 1500

  ctx.value = canvas.value.getContext('2d')
  if (!ctx.value)
    return

  window.addEventListener('resize', () => {
    if (!canvas.value || !ctx.value)
      return

    canvas.value.width = 1500
    canvas.value.height = 1500
  })
})

onUnmounted(() => {
  window.cancelAnimationFrame(theAnimation.value)
})

function hexToRgba(hex, alpha = 1) {
  // Remove the `#` if it exists
  hex = hex.replace(/^#/, '')

  // Parse hex into RGB values
  const r = Number.parseInt(hex.substring(0, 2), 16)
  const g = Number.parseInt(hex.substring(2, 4), 16)
  const b = Number.parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

function generateAndDrawCircle(bufferLength: Ref<number>, segment: number, segments: number = 0) {
  if (!ctx.value || !canvas.value || !dataArray.value)
    return

  const myPoints: number[] = []
  let amplitude = 0

  ctx.value.lineWidth = 8
  // ctx.value.shadowColor = 'white'
  // ctx.value.shadowBlur = 20

  const start = (bufferLength.value / segments) * segment
  const end = (bufferLength.value / segments) * (segment + 1)

  for (let i = start; i < end; i++) {
    // Calculate the opacity with the last circle being the most opaque
    const opacity = 1 - (segment / segments) + 0.4
    const rgbaColor = hexToRgba(color, opacity)

    const lineWidth = (0.01 * i) + (segment * 2)

    ctx.value.strokeStyle = rgbaColor
    ctx.value.lineWidth = lineWidth

    // if i is in after the first 1/4 of dataArray.value then do something
    if (i > dataArray.value.length / segments) {
      // If i is not in the last 1/4 of dataArray.value then do something
      if (i < dataArray.value.length - dataArray.value.length / segments) {
        amplitude = dataArray.value[Math.floor(i)]

        // Build the x and y on the circle at the amplitude of amplitude
        const angle = (i / (bufferLength.value / segments)) * Math.PI * 2
        const x = canvas.value.width / 2 + Math.sin(angle) * ((radius.value + (segment * distanceBetweenCircles)) + amplitude * angle * amplifier)
        const y = canvas.value.height / 2 + Math.cos(angle) * ((radius.value + (segment * distanceBetweenCircles)) + amplitude * angle * amplifier)

        myPoints.push(x)
        myPoints.push(y)
      }
    }
  }

  const tension = 0.2
  drawCurve(myPoints, tension, true) // default tension=0.5
}

function animate(timeStamp: number = 0) {
  if (!ctx.value || !audio1.value || !analyser.value || !canvas.value || !dataArray.value)
    return

  const deltaTime = timeStamp - lastTime.value
  lastTime.value = timeStamp

  if (timer.value > interval) {
    ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height)

    // Decibels for a given frequency
    analyser.value.getByteFrequencyData(dataArray.value)

    for (let i = 0; i < segments; i++)
      generateAndDrawCircle(bufferLength, i, segments)

    timer.value = 0
  }
  else {
    timer.value += deltaTime
  }

  theAnimation.value = window.requestAnimationFrame(animate)
}

function initAndAnalyseAudio() {
  audio1.value = new Audio(audioTrack)
  audioContext.value = new AudioContext()

  const audioSource = audioContext.value.createMediaElementSource(audio1.value)

  analyser.value = audioContext.value.createAnalyser()
  analyser.value.fftSize = numberOfSamples.value
  audioSource.connect(analyser.value)

  analyser.value.connect(audioContext.value.destination)

  bufferLength.value = analyser.value.frequencyBinCount
  dataArray.value = new Uint8Array(bufferLength.value)

  animate(0)
}

const playing = ref(false)
function toggleAudio() {
  if (playing.value && audio1.value) {
    audio1.value.pause()
    playing.value = false
    return
  }

  initAndAnalyseAudio()
  audio1.value?.play()
  playing.value = true
}

onMounted(() => {
  setTimeout(() => {
    initAndAnalyseAudio()
  }, 1000)
})
</script>

<template>
  <div class="flex justify-center overflow-hidden" @click="toggleAudio">
    <div class="relative">
      <canvas id="canvas1" class="w-screen lg:h-[500px] lg:w-[500px]" />
      <div class="absolute inset-0 flex justify-center items-center duration-200" :class="{ 'opacity-0': playing }">
        <Icon name="heroicons:play-20-solid" class="w-12 h-12" />
      </div>
    </div>
  </div>
</template>
